import React, { useState } from 'react';
import { Toast, ToastSkin } from 'wix-ui-tpa/cssVars';
import { VIEWER_HOOKS } from '@wix/pricing-plans-common/hooks';
import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';
import classes from './EditorPreviewNotification.css';

export const EditorPreviewNotification = () => {
  const { isPreview, isEditor } = useEnvironment();
  const { t } = useTranslation();
  const [isShown, setIsShown] = useState(isPreview);

  if (!isEditor && !isPreview) {
    return null;
  }

  return (
    <Toast
      data-hook={VIEWER_HOOKS.PLAN_CUSTOMIZATION_EDITOR_PREVIEW_NOTIFICATION}
      className={classes.previewNotification}
      skin={ToastSkin.status}
      isShown={isShown}
      onClose={() => setIsShown(false)}
      shouldShowCloseButton
    >
      {t('plan-customization.widget.editor-preview-notification')}
    </Toast>
  );
};
